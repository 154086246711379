import React from "react"

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import 'react-accessible-accordion/dist/fancy-example.css';
import { rhythm } from "../utils/typography";


export default class Zippy extends React.Component {
  render() {
    const { title, children } = this.props
    return (
      <Accordion allowZeroExpanded={true} style={{marginBottom: rhythm(1)}}>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton style={{padding:rhythm(0.4)}}>
              {title}
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel style={{padding:rhythm(0.4)}}>
            {children}
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
      )
    }
  }