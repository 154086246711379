import React from "react"
import rehypeReact from "rehype-react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Img from 'gatsby-image'
import Layout from "../components/layout"
import Zippy from "../components/zippy"
import SEO from "../components/seo"
import { rhythm, scale } from "../utils/typography"


const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: { "zippy": Zippy }
}).Compiler

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
          image={post.frontmatter.cover && post.frontmatter.cover.childImageSharp.fixed.src}
          imageAlt={post.frontmatter.coverAlt && post.frontmatter.coverAlt}
        />
        <article>
          <header>
            { post.frontmatter.cover !== null &&
              <Img style={{
                  height: rhythm(5),
                  marginBottom: rhythm(0.5),
                }} 
                alt={post.frontmatter.coverAlt} 
                title={post.frontmatter.coverAlt}
                sizes={post.frontmatter.cover.childImageSharp.sizes} 
              />
            }
            <h1
              style={{
                marginTop: rhythm(1),
                marginBottom: 0,
              }}
            >
              {post.frontmatter.title}
            </h1>
            <p
              style={{
                ...scale(-1 / 5),
                display: `block`,
                marginBottom: rhythm(0.5),
              }}
            >
              {post.frontmatter.date}
            </p>
          </header>
          <section>{renderAst(post.htmlAst)}</section>
          <hr
            style={{
              marginBottom: rhythm(1),
            }}
          />
          <footer>
            <Bio />
          </footer>
        </article>

        <nav>
          <ul
            style={{
              display: `flex`,
              flexWrap: `wrap`,
              justifyContent: `space-between`,
              listStyle: `none`,
              padding: 0,
            }}
          >
            <li>
              {previous && (
                <Link to={previous.fields.slug} rel="prev">
                  ← {previous.frontmatter.title}
                </Link>
              )}
            </li>
            <li>
              {next && (
                <Link to={next.fields.slug} rel="next">
                  {next.frontmatter.title} →
                </Link>
              )}
            </li>
          </ul>
        </nav>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      htmlAst
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        coverAlt
        cover {
          publicURL
          childImageSharp {
            sizes(maxWidth: 2000) {
              ...GatsbyImageSharpSizes
            }
            fixed(width: 1200, height:600) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`
